import { StaticImage } from 'gatsby-plugin-image'
import { React, View, StyleSheet, Image } from 'lib'
import { Theme } from 'app'
import BgImageMobile from '../../assets/about-bg-mobile.png'
import BgImage from '../../assets/about-bg.png'

const ImageSection: React.FC = () => {
  const isMobile = Theme.hooks.down('mid')
  const isTiny = Theme.hooks.down('tiny')
  const isLargeScreen = Theme.hooks.up('large')
  return (
    <View variant='flex column' style={styles.innerWrapper}>
      <View style={styles.shadow}/>

      <Image
        source={isLargeScreen ? BgImage : BgImageMobile}
        alt='Woman safely walking with her dog'
        style={{ ...styles.imageWrapper, ...(isLargeScreen ? { height: 'auto' } : { height: '100%' }) }}
      />

      <View style={styles.staticImageWrapper}>
        <StaticImage
          src='../../assets/screens.png'
          alt='Becon app screens'
          imgStyle={isMobile ? isTiny ? styles.screenshotTiny : styles.screenshotMobile : styles.screenshot}
          style={isMobile ? styles.screenshotWrapperMobile : styles.screenshotWrapper}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.createComponentStyleSheet({
  innerWrapper: {
    ...Theme.flex,
    ...Theme.fullWidth,
    position: 'relative',
    justifyContent: 'flex-end',
    maxHeight: '75vh',
    height: '75vh',
    [Theme.media.down('small')]: {
      height: '40vh',
    },
  },
  shadow: {
    position: 'absolute',
    inset: 0,
    backgroundColor: 'rgb(24, 24, 24, 0.4)',
    zIndex: -1,
  },
  imageWrapper: {
    ...Theme.absolute,
    ...Theme.whole,
    zIndex: -2,
    width: '100%',
    objectFit: 'cover',
  },
  screenshot: {
    objectFit: 'contain',
    width: '90%',
    height: '90%',
    transform: 'translate(24%, 12%)',
  },
  screenshotMobile: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    transform: 'translate(24%, 12%)',
  },
  screenshotTiny: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    transform: 'translate(0%, 4%)',
  },
  screenshotWrapper: {
    width: '60%',
    maxHeight: '80vh',
  },
  screenshotWrapperMobile: {
    width: '70%',
    maxHeight: '80vh',
  },
  staticImageWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    width: '100%',
    [Theme.media.down('tiny')]: {
      justifyContent: 'center',
    },
  },
})

export default ImageSection
