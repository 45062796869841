import { React } from 'lib'
import ImageSection from './ImageSection'
import AppAbout from './AppAbout'

function About() {
  return (
    <>
      <ImageSection/>
      <AppAbout/>
    </>
  )
}

export default About
