import { React, View, Text, StyleSheet, CenterWrapper } from 'lib'
import { Theme } from 'app'
import { PageTitle } from 'components'

const about = [
  'Life-long friends and city dwellers, Charlie Pothecary & Chris Miller, began developing a smart personal safety tool in early 2021. ',

  'The idea came at a time when tragic news stories of individuals suffering emergencies, accidents and attacks, or going missing in a variety of situations were making headlines at home in the UK and around the world. ',

  'They looked at what was already available, whether an app or device SOS feature, and found the space poorly served. They couldn’t find anything that was really simple to use, private, or most importantly, any app that was fully automatic. ',

  'Other apps relied on the user to physically interact with their phone when in an unsafe moment, or required them to share their live location and notify others each time they wished to use it. ',

  'The two friends set about creating a new kind of smart personal safety tool, one that could be used by anyone, quickly, discreetly and on all types of walking journeys, no matter where in the world they were taking place..',

  'And so, after months of development, rigorous testing, tweaking (and testing again) Becon, the world’s first fully automated walking safety app, was born!',
]


const AppAbout: React.FC = () => {
  return (
    <CenterWrapper style={styles.wrapper} contentContainerStyle={styles.innerWrapper}>
      <PageTitle location='About' title='Our Story'/>

      <View>
        {about.map((text, key) => <Text key={key} variant={['p3', 'marginTop:3']} text={text}/>)}
      </View>
    </CenterWrapper>
  )
}

const styles = StyleSheet.createComponentStyleSheet({
  wrapper: {
    backgroundColor: Theme.colors.white,
    position: 'relative',
  },
  innerWrapper: {
    flexDirection: 'column',
    marginTop: Theme.spacing(6),
    marginBottom: Theme.spacing(16),
    display: 'flex',
    gap: Theme.spacing(3),
    [Theme.media.down('small')]: {
      marginTop: Theme.spacing(3),
      gap: 0,
    },
  },
})

export default AppAbout
